import * as authApi from "./auth-api";

function isTokenExpired(token: string) {
  if (!token) return true; // If no token, consider it expired

  const payload = JSON.parse(atob(token.split(".")[1])); // Decode the JWT token

  const tokenExpireTime = payload.exp * 1000; // Token issued at (in milliseconds)
  const currentTime = Date.now();

  return currentTime > tokenExpireTime;
}

async function client(endpoint: string, httpConfig: any | undefined = {}) {
  const { data, token, headers: customHeaders, ...customConfig } = httpConfig;

  if (isTokenExpired(token)) {
    // Token is expired, redirect to login
    window.location.replace("/");
    await authApi.logout();
    return Promise.reject({ message: "Token expired, redirecting to login." });
  }

  const config = {
    method: data ? "POST" : "GET",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders
    },
    ...customConfig
  };

  return window.fetch(`/api/${endpoint}`, config).then(async response => {
    if (response.status === 401) {
      await authApi.logout();
      window.location.replace("/");
      // window.localStorage.removeItem(localStorageKey); // Assuming you're using localStorage
      // refresh the page for them
      // @ts-ignore
      // window.location.assign(window.location)
      return Promise.reject({ message: "Please re-authenticate." });
    }

    if (response.statusText === "No Content") {
      return null;
    }
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}

export { client };
